@media all {
	h2 {
		font-family: $font-ssp;
	}
}

	/*************************************** ACCUEIL ****************************************************/
@media all {
	#video {
		margin-top:91px;
		overflow:hidden;
		position:relative;
		margin-bottom:-10px;
		iframe {width:100%; height:auto;} // ==> taille de l'iframe gérée en jQuery dans le onResize d'index.php
		img {
			height:100%; width:100%;
			// object-fit:cover ;
		}
	}

	#presentation {
		background-color: $orange;
		padding : 75px 0;
		position : relative ;
		.wrap {
			color:$white; padding:0%;
			max-width:870px;
			text-align:justify;
			h1, h2 {
				text-align: center;				
				font-family: $font-ssp;
				color:white;
			}
			h1 {margin-bottom:30px;}
			h2 {margin-bottom:45px; font-size:25px;}
			strong {
				font-weight: normal;
				border-bottom: 1px solid #fff;
			}
			.accordeon_header, .accordeon_footer {
				text-align: center ;
				margin-top: 45px ;
				.accordeon_title {
					display: inline-block;
					margin: auto;
					cursor: pointer ;
					border: 2px solid #fff;
					border-radius: 50%;
					padding: 0px;
					width: 30px; height:30px; line-height:28px;
					font-weight: bold;
					padding-bottom: 2px;
					transform : rotate(0);
					@include smoothanimation;
					.moins {display:none;}
				}
			}
			.accordeon_header.active {
				.accordeon_title {
					.plus {display:none;}
					.moins {display:block;}
				}
			}
			.accordeon_body {margin-top : 45px;}
			.accordeon_content {
				.line {
					margin-bottom:45px;
					align-items:center;
					.img, .text {flex:1;}
					.img {height:320px;}
					.text {padding-left:30px;}
				}
				.line:nth-of-type(2n) {
					flex-flow:row-reverse;
					.text {padding-left:0; padding-right:30px;}
				}
				.line:last-child {margin-bottom:0;}
			}
		}
	}

	section#product {
		padding : 75px 0;
		@include shadow;
		h2 {
			font-size: 35px;
		}
		.flex {
			justify-content: center ;
			.item {
				position : relative;
				.caption {
					background-color :rgba(0,0,0,0.7);
					position :absolute;
					left :0;
					top : 0;
					width :100%;
					height :100% ;
					color :#fff ;
					padding:40px 50px;
					opacity : 0 ;
					@include smoothanimation ;
					.textcaption {
						position: absolute;
						top: 50%;
						-webkit-transform: translateY(-50%);
						transform: translateY(-50%);
						width: calc(100% - 100px);
						left: 50px;
						h3 {
							text-align: center;
							font-weight: normal;
							font-size:35px;
							margin-bottom: 20px;
						}
						p.button {
							margin-top : 30px;
							a {
								border-color : $white ;
								color :$white ;
								font-size: 17px;
								overflow: hidden;
								position: relative;
								z-index: 1;
								&::before {
									content: '';
									z-index: -1;
									position: absolute;
									top: 50%;
									right: 100%;
									margin: -15px 0 0 1px;
									width: 30px;
									height: 30px;
									border-radius: 50%;
									background:$white ;
									-webkit-transform-origin: left;
									transform-origin: left;
									-webkit-transform: scale3d(1,2,1);
									transform: scale3d(1,2,1);
									@include smoothanimation ;
								}
								&:hover {
									color :$grey ;
								}
								&:hover::before {
									-webkit-transform: scale3d(20,9,1);
									transform: scale3d(20,9,1);
									@include smoothanimation ;
								}
							}
						}

					}
				}
				&:hover .caption {
					opacity : 1 ;
					@include smoothanimation ;
				}
			}
		}
		.ourproduct {
			.flex .item img {width:100%; max-width:485px;}
		}
		.ourproduct p.button {
			margin : 45px 0 0;
			text-align :center ;
			a {
				border: 1px solid #ff6200;
				padding: 10px 20px;
				font-weight: bold;
				display: inline-block;
				overflow: hidden;
				position: relative;
				z-index: 1;
				&::before {
					content: '';
					z-index: -1;
					position: absolute;
					top: 50%;
					right: 100%;
					margin: -15px 0 0 1px;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background:$orange ;
					-webkit-transform-origin: left;
					transform-origin: left;
					-webkit-transform: scale3d(1,2,1);
					transform: scale3d(1,2,1);
					@include smoothanimation ;
				}
				&:hover {
					color :$white ;
				}
				&:hover::before {
					-webkit-transform: scale3d(20,9,1);
					transform: scale3d(20,9,1);
					@include smoothanimation ;
				}
			}
		}
		.ourproduct + .numbers {
			margin-top : 90px;
		}
		.numbers .blocnumber{
			display : table;
			margin : auto ;
			position: relative;
			margin-top : 30px;
			.flex {
				justify-content: center;
				.item  {
					text-align :center ;
					strong {
						background-color: $white;
						display: inline-block ;
						span.chiffre , span.indication{
							font-size: 35px;
							padding: 10px 5px;
							font-weight: 600;
							color: $orange;
							background-color: $white;
						}
					}
					span.legend {display:block; line-height:1.125em; padding-top:10px;}
					+ .item {
						padding-left: 160px;
						&::before {
							content :'';
							width : 160px ;
							height : 2px;
							background-color :$orange ;
							position :absolute;
							top : 15px;
							left : 0;
							z-index: -1;
						}
					}
				}
			}
		}
	}
}
@media all {

	header .mainmenu {
		margin: auto;
		ul.nav-menu > li {
			position: relative;
			display: inline-block;
			> a {
				text-transform: uppercase;
				color: $orange;
				padding-bottom: 15px;
				letter-spacing: 0.05em;
				font-weight: bold;
				&::after {
					content: '';
					height: 2px;
					width: 0;
					background-color: $orange;
					position: absolute;
					bottom: -10px;
					left :0 ;
					@include smoothanimation ;
				}
				&:hover::after {
					width: 100%;
					@include smoothanimation ;
				}
			}
			+ li {
				margin-left: 100px;
				a::before {
					content: '';
					height: 15px;
					width: 2px;
					background-color: $orange;
					position: absolute;
					left: -50px;
					top: 7px;
				}
			}
			> .sub-menu {
				display:none;
				// visibility:none;
				overflow:hidden;
				-webkit-transition: opacity 200ms;
				-moz-transition: opacity 200ms;
				-ms-transition: opacity 200ms;
				-o-transition: opacity 200ms;
				transition: opacity 200ms;
				position: absolute; top:25px; left:0; width:100%; height:auto;
				padding: 40px 15px 20px;
				// padding: 0;				
				// height: 1px;
				// width: 1px;
				// overflow: hidden;
				z-index: 100;
				background-color: rgba(255,255,255,0.8);
				margin: 15px 0 0;
				> li {
					line-height: normal ;
					font-size: 18px;
					+ li {margin-top : 10px ;}
					a:before {display:none;}
				}
				@include opacity(0);
			}
		}
		li.menu-item-has-children:hover {
			.sub-menu {
				display:block;
				@include opacity(1);
				> li.current-menu-item a, > li:hover a {font-weight : bold ;}
			}
		}
		li.current-menu-item a::after{
			width: 100%;
			@include smoothanimation ;
		}
	}

	/*** MENU ***/

	.menu-basic {
		overflow-x: hidden;
	}

	.menu-trigger {
		width: 35px;
		height: 42px;
		margin: 0;
		position: relative;
		cursor: pointer;
		z-index: 999;
		display :none ;
		span {
			display: block;
			background: $orange ;
			border-radius: 0;
		}

		#hamburger {
			position: absolute;
			height: 100%;
			width: 100%;

			span {
				width: 35px;
				height: 3px;
				position: relative;
				top: 0;
				left: 0;
				margin: 7px 0;

				&:nth-child(1) {
					transition-delay: .5s;
				}

				&:nth-child(2) {
					transition-delay: .625s;
				}

				&:nth-child(3) {
					transition-delay: .75s;
				}
			}
		}

		&.active #hamburger span {
			width: 0%;

			&:nth-child(1) {
				transition-delay: 0s;
			}

			&:nth-child(2) {
				transition-delay: .125s;
			}

			&:nth-child(3) {
				transition-delay: .25s;
			}
		}

		#cross {
			position: fixed;
			height: 35px;
			width: 31px;
			transform: rotate(45deg);
			right: 50px;

			span {
				&:nth-child(1) {
					height: 0%;
					width: 3px;
					position: absolute;
					top: 0%;
					left: 15px;
					transition-delay: 0s;
					background: $orange ;
				}

				&:nth-child(2) {
					width: 0%;
					height: 3px;
					position: absolute;
					left: 0;
					top: 15px;
					transition-delay: .25s;
					background: $orange ;
				}
			}
		}

		&.active #cross span {
			&:nth-child(1) {
				height: 35px;
				transition-delay: .625s;
			}

			&:nth-child(2) {
				width: 35px;
				transition-delay: .375s;
				top: 16px;
				left: -1px;
			}
		}
	}

	.menu-basic {
		position: fixed;
		right: 0;
		top: 0;
		height: 100%;
		display: block;
		transform: translateX(100%);
		transition: all 0.8s cubic-bezier(0.99, 0.01, 0.02, 0.99);
		z-index: 50;
		padding: 91px 0 0 0;
		nav {
			width:100%; height:100%;
			margin:auto;
			background-color:white;
			padding:30px 50px 0 50px;
			border:1px solid #EEE;
			text-align:right;
			ul.nav-menu {
				display: inline-block;
				padding-left: 0;
				width: 100%;
				> li {
					display: block;
					margin: 0;
					> {
						a, span {
							text-transform: uppercase;
							color:$orange ;
							padding:5px 0;
							letter-spacing: .05em;
							font-weight: 700;
							position: relative;
							display: inline-block;
							overflow: hidden;
							font-size:1.25em;
							&::before {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background:$orange ;
								transform: translate3d(-100%,0,0) translate3d(-1px,0,0);
								transition: transform 0.7s;
								transition-timing-function: ease;
								transition-timing-function: cubic-bezier(.7,0,.3,1);
							}
							&:hover::before {transform: translate3d(100%,0,0) translate3d(1px,0,0);}
						}
					}
					+ li {margin-top : 10px ;}
				}
				ul.sub-menu > li {
					margin-left:10px ;
					> a {
						padding:5px;
						color:$orange;
						font-size:1.125em;
					}
					ul > li a:hover {color: #a9cc46;}
				}
			}
		}
		&.open {transform: translateX(0);}

		.headmenu {margin: 100px 20px 49px; text-align: center;}
	}

	.bg-menu-mobile {
		display:none;
		// @include opacity(0); 
		// @include animate(.7s);
		width:100%; height:100%;
		background-color:rgba(0,0,0,.5);
		position:fixed; top:0; left:0; z-index:2;
		// &.open {
		// 	display:block;
		// 	@include opacity(1);
		// }
	}
}
/********* COLORS *******/

$orange : #ff6200 ;
$greyclear : #e0e0e0;
$grey : #3b3b3b ;
$white : #ffffff ;

/******** FONTS ***********/

@font-face {
	font-family: 'Calibri';
	src: url('../ocea-fdc/fonts/Calibri.eot') format('embedded-opentype');
	src:  url('../ocea-fdc/fonts/Calibri.woff') format('woff'), url('../ocea-fdc/fonts/Calibri.ttf')  format('truetype'), url('../ocea-fdc/fonts/Calibri.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

$font-ssp: 'Source Sans Pro', sans-serif;
$font-calibri: 'Calibri', sans-serif;


/******** Animation ********/

@mixin smoothanimation {
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

@mixin shadow {
	-webkit-box-shadow: inset 0px 0px 42px -28px rgba(0,0,0,0.27);
	-moz-box-shadow: inset 0px 0px 42px -28px rgba(0,0,0,0.27);
	box-shadow: inset 0px 0px 42px -28px rgba(0,0,0,0.27);
}

@mixin animate($time: 0.3s) {
	-webkit-transition: all $time ease-in-out 0s;
	-moz-transition: all $time ease-in-out 0s;
	-ms-transition: all $time ease-in-out 0s;
	-o-transition: all $time ease-in-out 0s;
	transition: all $time ease-in-out 0s;	
}

@mixin opacity($value) {
    $valueIE : $value * 100;    
	opacity: $value;
    filter: alpha(opacity= $valueIE ); 
}

@mixin rotate($angle) {
	transform:rotate($angle);
	-ms-transform:rotate($angle); 	/* Internet Explorer */
	-moz-transform:rotate($angle); 	/* Firefox */
	-webkit-transform:rotate($angle);/* Safari et Chrome */
	-o-transform:rotate($angle); 	/* Opera */	
}

@mixin shadow($color:#222, $strenght:7px) {
	-moz-box-shadow: 0px 0px $strenght 0px $color;
	-webkit-box-shadow: 0px 0px $strenght 0px $color;
	-o-box-shadow: 0px 0px $strenght 0px $color;
	box-shadow: 0px 0px $strenght 0px $color;
	filter:progid:DXImageTransform.Microsoft.Shadow(color=$color, Direction=NaN, Strength=7);
}
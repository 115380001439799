@charset "UTF-8";
/****** IMPORT PARTS SASS ********/
/********* COLORS *******/
/******** FONTS ***********/
@font-face {
  font-family: 'Calibri';
  src: url("../ocea-fdc/fonts/Calibri.eot") format("embedded-opentype");
  src: url("../ocea-fdc/fonts/Calibri.woff") format("woff"), url("../ocea-fdc/fonts/Calibri.ttf") format("truetype"), url("../ocea-fdc/fonts/Calibri.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

/******** Animation ********/
@media all {
  h1 {
    font-size: 35px;
    line-height: 1.125em; }
  h2 {
    text-align: center;
    font-family: "Source Sans Pro", sans-serif;
    margin-bottom: 45px;
    color: #ff6200;
    display: inline-block;
    width: 100%;
    line-height: 1.25em; }
  a {
    text-decoration: none;
    color: #ff6200; }
  ul {
    list-style: none; }
  img {
    border: 0;
    height: auto;
    max-width: 100%;
    vertical-align: bottom; }
  .wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 1630px; }
  .page-template-default article .wrap {
    max-width: 1170px; }
  #menu-header a:hover {
    text-decoration: underline; }
  .wrap::after {
    display: block;
    clear: both;
    content: ""; }
  a, a:hover {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
  .flex {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap; }
  .container {
    width: 80%;
    margin: auto; }
  body {
    font: normal 19px/29px "Calibri", sans-serif; }
  /*************************************** HEADER ****************************************************/
  header {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 30;
    top: 0;
    width: 100%;
    margin: auto;
    padding: 20px 5%;
    background-color: #ffffff;
    -moz-box-shadow: 0px 0px 7px 0px #777;
    -webkit-box-shadow: 0px 0px 7px 0px #777;
    -o-box-shadow: 0px 0px 7px 0px #777;
    box-shadow: 0px 0px 7px 0px #777;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=$color, Direction=NaN, Strength=7); }
    header .flex {
      align-items: center;
      justify-content: space-between; }
    header + * {
      margin-top: 92px; }
  /*** LANGUES ***/
  header .language {
    margin-left: auto; }
    header .language .wpml-ls-legacy-dropdown {
      width: 100px; }
    header .language .wpml-ls-legacy-dropdown li.wpml-ls-slot-languages a.js-wpml-ls-item-toggle {
      border-color: transparent;
      /*span.wpml-ls-native {
                display : none ;
                &:first-letter  {
                    display : block ;
                }
            }*/ }
      header .language .wpml-ls-legacy-dropdown li.wpml-ls-slot-languages a.js-wpml-ls-item-toggle::after {
        display: block;
        width: 21px;
        height: 11px;
        content: '';
        background: url(./images/fleche.png) center center no-repeat;
        background-size: cover;
        position: absolute;
        border: none;
        top: 12px;
        right: 15px;
        -webkit-transition: all .2s ease-in-out 0s;
        transition: all .2s ease-in-out 0s; }
      header .language .wpml-ls-legacy-dropdown li.wpml-ls-slot-languages a.js-wpml-ls-item-toggle:hover, header .language .wpml-ls-legacy-dropdown li.wpml-ls-slot-languages a.js-wpml-ls-item-toggle:focus {
        background: transparent; }
      header .language .wpml-ls-legacy-dropdown li.wpml-ls-slot-languages a.js-wpml-ls-item-toggle span.wpml-ls-native {
        text-transform: uppercase;
        color: #ff6200;
        letter-spacing: .05em;
        font-weight: 700; }
      header .language .wpml-ls-legacy-dropdown li.wpml-ls-slot-languages a.js-wpml-ls-item-toggle a.js-wpml-ls-item-toggle span {
        display: none; }
      header .language .wpml-ls-legacy-dropdown li.wpml-ls-slot-languages a.js-wpml-ls-item-toggle a.js-wpml-ls-item-toggle span:first-letter {
        display: inline-block; }
    header .language .wpml-ls-legacy-dropdown a {
      color: #ff6200;
      border: none;
      background: rgba(255, 255, 255, 0.8); }
    header .language .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
      border-top: none;
      background: transparent;
      top: calc(100% + 20px);
      padding: 10px 0; }
    header .language .wpml-ls-legacy-dropdown a:hover, header .language .wpml-ls-legacy-dropdown a:focus, header .language .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover > a {
      background: rgba(255, 255, 255, 0.8);
      font-weight: 700;
      color: #ff6200; }
    header .language .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover > a {
      background: transparent; }
  /************************** FIL ARIANE ****************************/
  /** contenu **/
  .bandeau {
    position: relative;
    height: 475px;
    overflow: hidden; }
    .bandeau img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .bandeau .maintitle {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(255, 255, 255, 0.8); }
      .bandeau .maintitle h1 {
        font-size: 35px;
        padding: 25px 0 20px;
        color: #ff6200;
        text-align: center;
        text-transform: uppercase; }
  article {
    padding: 90px 0;
    -webkit-box-shadow: inset 0 0 42px -28px rgba(0, 0, 0, 0.27);
    box-shadow: inset 0 0 42px -28px rgba(0, 0, 0, 0.27); }
    article .wrap h2 {
      margin: 0 0 30px;
      display: inline-block;
      width: 100%;
      font-size: 35px; }
      article .wrap h2:first-child {
        margin-bottom: 60px; }
    article .wrap * + h2 {
      margin-top: 60px; }
    article .wrap h3 {
      font-size: 25px;
      margin-bottom: 30px;
      font-weight: 500;
      color: #3b3b3b; }
    article .wrap * + h3 {
      margin-top: 30px; }
    article .wrap p.margin-bottom {
      margin-bottom: 60px; }
    article .wrap ul:not(.slides) {
      margin: 15px 0; }
    article .wrap .imagetexte.flex {
      align-items: top;
      margin: 0 0 60px; }
      article .wrap .imagetexte.flex:last-child {
        margin-bottom: 0px; }
      article .wrap .imagetexte.flex .texte {
        width: 485px;
        text-align: justify;
        margin-left: 60px;
        flex: 0.75;
        line-height: 1.4em; }
      article .wrap .imagetexte.flex .images {
        margin-top: 5px;
        width: 565px;
        max-width: 565px;
        flex: 1; }
    article .wrap .imagetexte.flex.pageproduit {
      align-items: flex-start; }
    article .wrap .img-rightalign.imagetexte.flex .images {
      order: 2; }
    article .wrap .img-rightalign.imagetexte.flex .texte {
      margin-right: 60px;
      margin-left: 0; }
  article li {
    padding-left: 1em;
    text-indent: -1em; }
  article li li {
    margin-left: 20px; }
  article li ul {
    margin-bottom: 5px; }
  article li:before {
    content: "\2022\ ";
    padding-right: 5px; }
  .alignright {
    float: right;
    margin-left: 30px; }
  .alignleft {
    float: left;
    margin-right: 30px; }
  .aligncenter {
    display: block;
    margin: 0 auto; }
  .flexslider {
    position: relative; }
    .flexslider .flex-viewport .slides li {
      padding-left: 0;
      text-indent: 0; }
      .flexslider .flex-viewport .slides li:before {
        display: none; }
    .flexslider .flex-control-nav {
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      justify-content: center;
      margin-top: -30px;
      position: relative; }
      .flexslider .flex-control-nav li {
        padding-left: 0;
        text-indent: 0;
        list-style-type: none; }
        .flexslider .flex-control-nav li:before {
          display: none; }
        .flexslider .flex-control-nav li a {
          font-size: 0;
          background-color: rgba(255, 99, 25, 0.5);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-block; }
        .flexslider .flex-control-nav li a.flex-active {
          background-color: #ff6319; }
        .flexslider .flex-control-nav li + li {
          margin-left: 10px; }
  .flexslider .flex-viewport {
    border: 1px solid rgba(0, 0, 0, 0.05);
    max-height: 360px; }
  .flexslider.slidersmall {
    margin: 15px 0 30px; }
    .flexslider.slidersmall .flex-viewport {
      border: none; }
    .flexslider.slidersmall ul.slides li {
      border: 1px solid rgba(0, 0, 0, 0.05); }
  /** catégorie **/
  /**.article-blog { padding: 35px 30px; background:#f2f2f2;}
		.article-blog + .article-blog { margin-top:35px;}
		.img-blog { float:left; margin-right:3%;}
		.texte-blog { overflow:auto;}
		.article-blog p.readmore a { margin-top:35px;}

		/* pagination */
  /**.pagination { margin-top:40px; text-align:center;}
	.pagination ul{display:table; margin:auto; border-collapse: separate; }
	.pagination li{display:table-cell; padding-right: 15px; width:55px; height:55px;}
	.pagination li span, .pagination li span.current, .pagination li a{ padding:12px 20px; border-radius:50%;  font-weight: 700; font-size: 20px; line-height:22px; text-align: center;}
	.pagination li a{ background:#f0eee6; color:#000;-webkit-transition: all 500ms ease-in-out; -o-transition: all 500ms ease-in-out; transition: all 500ms ease-in-out;}
	.pagination li a:hover, .pagination li span, .pagination li span.current{background:#404040; color:#fff;}
	article .pagination li:before { content:none;}




	/*************************************** PIED DE PAGE ****************************************************/
  .prefooter {
    background: #e0e0e0;
    padding: 75px 0;
    text-align: center; }
    .prefooter h2 {
      font-size: 35px;
      margin-bottom: 45px;
      color: #3b3b3b; }
    .prefooter #lightgallery {
      margin-top: 30px;
      text-align: center; }
      .prefooter #lightgallery li {
        display: inline-block;
        margin: 0 15px 30px;
        max-width: 150px; }
        .prefooter #lightgallery li img {
          max-width: 150px; }
  footer {
    padding: 30px 0;
    background-color: #ff6200;
    font-family: "Calibri", sans-serif;
    font-weight: bold; }
    footer .flex {
      align-items: center;
      justify-content: space-between;
      width: 100%; }
      footer .flex #menu-footer ul li {
        display: inline-block; }
        footer .flex #menu-footer ul li a {
          color: #ffffff;
          position: relative; }
        footer .flex #menu-footer ul li + li a {
          padding-left: 20px;
          margin-left: 20px; }
          footer .flex #menu-footer ul li + li a::before {
            content: '';
            height: 15px;
            width: 2px;
            background-color: #ffffff;
            position: absolute;
            left: 0;
            top: 2px; }
    footer .IC-link img, footer .IClink img {
      height: 20px; } }

@media all {
  h2 {
    font-family: "Source Sans Pro", sans-serif; } }

/*************************************** ACCUEIL ****************************************************/
@media all {
  #video {
    margin-top: 91px;
    overflow: hidden;
    position: relative;
    margin-bottom: -10px; }
    #video iframe {
      width: 100%;
      height: auto; }
    #video img {
      height: 100%;
      width: 100%; }
  #presentation {
    background-color: #ff6200;
    padding: 75px 0;
    position: relative; }
    #presentation .wrap {
      color: #ffffff;
      padding: 0%;
      max-width: 870px;
      text-align: justify; }
      #presentation .wrap h1, #presentation .wrap h2 {
        text-align: center;
        font-family: "Source Sans Pro", sans-serif;
        color: white; }
      #presentation .wrap h1 {
        margin-bottom: 30px; }
      #presentation .wrap h2 {
        margin-bottom: 45px;
        font-size: 25px; }
      #presentation .wrap strong {
        font-weight: normal;
        border-bottom: 1px solid #fff; }
      #presentation .wrap .accordeon_header, #presentation .wrap .accordeon_footer {
        text-align: center;
        margin-top: 45px; }
        #presentation .wrap .accordeon_header .accordeon_title, #presentation .wrap .accordeon_footer .accordeon_title {
          display: inline-block;
          margin: auto;
          cursor: pointer;
          border: 2px solid #fff;
          border-radius: 50%;
          padding: 0px;
          width: 30px;
          height: 30px;
          line-height: 28px;
          font-weight: bold;
          padding-bottom: 2px;
          transform: rotate(0);
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          #presentation .wrap .accordeon_header .accordeon_title .moins, #presentation .wrap .accordeon_footer .accordeon_title .moins {
            display: none; }
      #presentation .wrap .accordeon_header.active .accordeon_title .plus {
        display: none; }
      #presentation .wrap .accordeon_header.active .accordeon_title .moins {
        display: block; }
      #presentation .wrap .accordeon_body {
        margin-top: 45px; }
      #presentation .wrap .accordeon_content .line {
        margin-bottom: 45px;
        align-items: center; }
        #presentation .wrap .accordeon_content .line .img, #presentation .wrap .accordeon_content .line .text {
          flex: 1; }
        #presentation .wrap .accordeon_content .line .img {
          height: 320px; }
        #presentation .wrap .accordeon_content .line .text {
          padding-left: 30px; }
      #presentation .wrap .accordeon_content .line:nth-of-type(2n) {
        flex-flow: row-reverse; }
        #presentation .wrap .accordeon_content .line:nth-of-type(2n) .text {
          padding-left: 0;
          padding-right: 30px; }
      #presentation .wrap .accordeon_content .line:last-child {
        margin-bottom: 0; }
  section#product {
    padding: 75px 0;
    -moz-box-shadow: 0px 0px 7px 0px #222;
    -webkit-box-shadow: 0px 0px 7px 0px #222;
    -o-box-shadow: 0px 0px 7px 0px #222;
    box-shadow: 0px 0px 7px 0px #222;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=$color, Direction=NaN, Strength=7); }
    section#product h2 {
      font-size: 35px; }
    section#product .flex {
      justify-content: center; }
      section#product .flex .item {
        position: relative; }
        section#product .flex .item .caption {
          background-color: rgba(0, 0, 0, 0.7);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          color: #fff;
          padding: 40px 50px;
          opacity: 0;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          section#product .flex .item .caption .textcaption {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: calc(100% - 100px);
            left: 50px; }
            section#product .flex .item .caption .textcaption h3 {
              text-align: center;
              font-weight: normal;
              font-size: 35px;
              margin-bottom: 20px; }
            section#product .flex .item .caption .textcaption p.button {
              margin-top: 30px; }
              section#product .flex .item .caption .textcaption p.button a {
                border-color: #ffffff;
                color: #ffffff;
                font-size: 17px;
                overflow: hidden;
                position: relative;
                z-index: 1; }
                section#product .flex .item .caption .textcaption p.button a::before {
                  content: '';
                  z-index: -1;
                  position: absolute;
                  top: 50%;
                  right: 100%;
                  margin: -15px 0 0 1px;
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  background: #ffffff;
                  -webkit-transform-origin: left;
                  transform-origin: left;
                  -webkit-transform: scale3d(1, 2, 1);
                  transform: scale3d(1, 2, 1);
                  -webkit-transition: all 0.5s ease-in-out;
                  -moz-transition: all 0.5s ease-in-out;
                  -ms-transition: all 0.5s ease-in-out;
                  -o-transition: all 0.5s ease-in-out;
                  transition: all 0.5s ease-in-out; }
                section#product .flex .item .caption .textcaption p.button a:hover {
                  color: #3b3b3b; }
                section#product .flex .item .caption .textcaption p.button a:hover::before {
                  -webkit-transform: scale3d(20, 9, 1);
                  transform: scale3d(20, 9, 1);
                  -webkit-transition: all 0.5s ease-in-out;
                  -moz-transition: all 0.5s ease-in-out;
                  -ms-transition: all 0.5s ease-in-out;
                  -o-transition: all 0.5s ease-in-out;
                  transition: all 0.5s ease-in-out; }
        section#product .flex .item:hover .caption {
          opacity: 1;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
    section#product .ourproduct .flex .item img {
      width: 100%;
      max-width: 485px; }
    section#product .ourproduct p.button {
      margin: 45px 0 0;
      text-align: center; }
      section#product .ourproduct p.button a {
        border: 1px solid #ff6200;
        padding: 10px 20px;
        font-weight: bold;
        display: inline-block;
        overflow: hidden;
        position: relative;
        z-index: 1; }
        section#product .ourproduct p.button a::before {
          content: '';
          z-index: -1;
          position: absolute;
          top: 50%;
          right: 100%;
          margin: -15px 0 0 1px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #ff6200;
          -webkit-transform-origin: left;
          transform-origin: left;
          -webkit-transform: scale3d(1, 2, 1);
          transform: scale3d(1, 2, 1);
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
        section#product .ourproduct p.button a:hover {
          color: #ffffff; }
        section#product .ourproduct p.button a:hover::before {
          -webkit-transform: scale3d(20, 9, 1);
          transform: scale3d(20, 9, 1);
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
    section#product .ourproduct + .numbers {
      margin-top: 90px; }
    section#product .numbers .blocnumber {
      display: table;
      margin: auto;
      position: relative;
      margin-top: 30px; }
      section#product .numbers .blocnumber .flex {
        justify-content: center; }
        section#product .numbers .blocnumber .flex .item {
          text-align: center; }
          section#product .numbers .blocnumber .flex .item strong {
            background-color: #ffffff;
            display: inline-block; }
            section#product .numbers .blocnumber .flex .item strong span.chiffre, section#product .numbers .blocnumber .flex .item strong span.indication {
              font-size: 35px;
              padding: 10px 5px;
              font-weight: 600;
              color: #ff6200;
              background-color: #ffffff; }
          section#product .numbers .blocnumber .flex .item span.legend {
            display: block;
            line-height: 1.125em;
            padding-top: 10px; }
          section#product .numbers .blocnumber .flex .item + .item {
            padding-left: 160px; }
            section#product .numbers .blocnumber .flex .item + .item::before {
              content: '';
              width: 160px;
              height: 2px;
              background-color: #ff6200;
              position: absolute;
              top: 15px;
              left: 0;
              z-index: -1; } }

@media all {
  header .mainmenu {
    margin: auto; }
    header .mainmenu ul.nav-menu > li {
      position: relative;
      display: inline-block; }
      header .mainmenu ul.nav-menu > li > a {
        text-transform: uppercase;
        color: #ff6200;
        padding-bottom: 15px;
        letter-spacing: 0.05em;
        font-weight: bold; }
        header .mainmenu ul.nav-menu > li > a::after {
          content: '';
          height: 2px;
          width: 0;
          background-color: #ff6200;
          position: absolute;
          bottom: -10px;
          left: 0;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
        header .mainmenu ul.nav-menu > li > a:hover::after {
          width: 100%;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
      header .mainmenu ul.nav-menu > li + li {
        margin-left: 100px; }
        header .mainmenu ul.nav-menu > li + li a::before {
          content: '';
          height: 15px;
          width: 2px;
          background-color: #ff6200;
          position: absolute;
          left: -50px;
          top: 7px; }
      header .mainmenu ul.nav-menu > li > .sub-menu {
        display: none;
        overflow: hidden;
        -webkit-transition: opacity 200ms;
        -moz-transition: opacity 200ms;
        -ms-transition: opacity 200ms;
        -o-transition: opacity 200ms;
        transition: opacity 200ms;
        position: absolute;
        top: 25px;
        left: 0;
        width: 100%;
        height: auto;
        padding: 40px 15px 20px;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.8);
        margin: 15px 0 0;
        opacity: 0;
        filter: alpha(opacity=0); }
        header .mainmenu ul.nav-menu > li > .sub-menu > li {
          line-height: normal;
          font-size: 18px; }
          header .mainmenu ul.nav-menu > li > .sub-menu > li + li {
            margin-top: 10px; }
          header .mainmenu ul.nav-menu > li > .sub-menu > li a:before {
            display: none; }
    header .mainmenu li.menu-item-has-children:hover .sub-menu {
      display: block;
      opacity: 1;
      filter: alpha(opacity=100); }
      header .mainmenu li.menu-item-has-children:hover .sub-menu > li.current-menu-item a, header .mainmenu li.menu-item-has-children:hover .sub-menu > li:hover a {
        font-weight: bold; }
    header .mainmenu li.current-menu-item a::after {
      width: 100%;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out; }
  /*** MENU ***/
  .menu-basic {
    overflow-x: hidden; }
  .menu-trigger {
    width: 35px;
    height: 42px;
    margin: 0;
    position: relative;
    cursor: pointer;
    z-index: 999;
    display: none; }
    .menu-trigger span {
      display: block;
      background: #ff6200;
      border-radius: 0; }
    .menu-trigger #hamburger {
      position: absolute;
      height: 100%;
      width: 100%; }
      .menu-trigger #hamburger span {
        width: 35px;
        height: 3px;
        position: relative;
        top: 0;
        left: 0;
        margin: 7px 0; }
        .menu-trigger #hamburger span:nth-child(1) {
          transition-delay: .5s; }
        .menu-trigger #hamburger span:nth-child(2) {
          transition-delay: .625s; }
        .menu-trigger #hamburger span:nth-child(3) {
          transition-delay: .75s; }
    .menu-trigger.active #hamburger span {
      width: 0%; }
      .menu-trigger.active #hamburger span:nth-child(1) {
        transition-delay: 0s; }
      .menu-trigger.active #hamburger span:nth-child(2) {
        transition-delay: .125s; }
      .menu-trigger.active #hamburger span:nth-child(3) {
        transition-delay: .25s; }
    .menu-trigger #cross {
      position: fixed;
      height: 35px;
      width: 31px;
      transform: rotate(45deg);
      right: 50px; }
      .menu-trigger #cross span:nth-child(1) {
        height: 0%;
        width: 3px;
        position: absolute;
        top: 0%;
        left: 15px;
        transition-delay: 0s;
        background: #ff6200; }
      .menu-trigger #cross span:nth-child(2) {
        width: 0%;
        height: 3px;
        position: absolute;
        left: 0;
        top: 15px;
        transition-delay: .25s;
        background: #ff6200; }
    .menu-trigger.active #cross span:nth-child(1) {
      height: 35px;
      transition-delay: .625s; }
    .menu-trigger.active #cross span:nth-child(2) {
      width: 35px;
      transition-delay: .375s;
      top: 16px;
      left: -1px; }
  .menu-basic {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    display: block;
    transform: translateX(100%);
    transition: all 0.8s cubic-bezier(0.99, 0.01, 0.02, 0.99);
    z-index: 50;
    padding: 91px 0 0 0; }
    .menu-basic nav {
      width: 100%;
      height: 100%;
      margin: auto;
      background-color: white;
      padding: 30px 50px 0 50px;
      border: 1px solid #EEE;
      text-align: right; }
      .menu-basic nav ul.nav-menu {
        display: inline-block;
        padding-left: 0;
        width: 100%; }
        .menu-basic nav ul.nav-menu > li {
          display: block;
          margin: 0; }
          .menu-basic nav ul.nav-menu > li > a, .menu-basic nav ul.nav-menu > li > span {
            text-transform: uppercase;
            color: #ff6200;
            padding: 5px 0;
            letter-spacing: .05em;
            font-weight: 700;
            position: relative;
            display: inline-block;
            overflow: hidden;
            font-size: 1.25em; }
            .menu-basic nav ul.nav-menu > li > a::before, .menu-basic nav ul.nav-menu > li > span::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #ff6200;
              transform: translate3d(-100%, 0, 0) translate3d(-1px, 0, 0);
              transition: transform 0.7s;
              transition-timing-function: ease;
              transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1); }
            .menu-basic nav ul.nav-menu > li > a:hover::before, .menu-basic nav ul.nav-menu > li > span:hover::before {
              transform: translate3d(100%, 0, 0) translate3d(1px, 0, 0); }
          .menu-basic nav ul.nav-menu > li + li {
            margin-top: 10px; }
        .menu-basic nav ul.nav-menu ul.sub-menu > li {
          margin-left: 10px; }
          .menu-basic nav ul.nav-menu ul.sub-menu > li > a {
            padding: 5px;
            color: #ff6200;
            font-size: 1.125em; }
          .menu-basic nav ul.nav-menu ul.sub-menu > li ul > li a:hover {
            color: #a9cc46; }
    .menu-basic.open {
      transform: translateX(0); }
    .menu-basic .headmenu {
      margin: 100px 20px 49px;
      text-align: center; }
  .bg-menu-mobile {
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2; } }

@media all {
  .others {
    -moz-box-shadow: 0px 0px 7px 0px #222;
    -webkit-box-shadow: 0px 0px 7px 0px #222;
    -o-box-shadow: 0px 0px 7px 0px #222;
    box-shadow: 0px 0px 7px 0px #222;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=$color, Direction=NaN, Strength=7); }
    .others .item {
      position: relative;
      flex: 1; }
      .others .item > img {
        height: 330px;
        object-fit: cover;
        width: 100%; }
      .others .item .caption {
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        padding: 40px 50px;
        opacity: 0;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
        .others .item .caption .textcaption {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          width: 100%;
          left: 0; }
          .others .item .caption .textcaption h3 {
            font-size: 35px;
            font-weight: normal;
            line-height: 1.125em; }
          .others .item .caption .textcaption p.button a {
            display: inline-block;
            border: 1px solid #fff;
            color: #fff;
            padding: 10px 15px;
            font-weight: bold;
            font-size: 17px;
            margin-top: 35px;
            overflow: hidden;
            position: relative;
            z-index: 1; }
            .others .item .caption .textcaption p.button a::before {
              content: '';
              z-index: -1;
              position: absolute;
              top: 50%;
              right: 100%;
              margin: -15px 0 0 1px;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background: #ffffff;
              -webkit-transform-origin: left;
              transform-origin: left;
              -webkit-transform: scale3d(1, 2, 1);
              transform: scale3d(1, 2, 1);
              -webkit-transition: all 0.5s ease-in-out;
              -moz-transition: all 0.5s ease-in-out;
              -ms-transition: all 0.5s ease-in-out;
              -o-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out; }
            .others .item .caption .textcaption p.button a:hover {
              color: #3b3b3b; }
            .others .item .caption .textcaption p.button a:hover::before {
              -webkit-transform: scale3d(20, 9, 1);
              transform: scale3d(20, 9, 1);
              -webkit-transition: all 0.5s ease-in-out;
              -moz-transition: all 0.5s ease-in-out;
              -ms-transition: all 0.5s ease-in-out;
              -o-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out; }
      .others .item:hover .caption {
        opacity: 1;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
  article p.button.center {
    text-align: center;
    margin-top: 30px;
    display: inline-block;
    width: 100%; }
    article p.button.center a {
      border: 1px solid #ff6200;
      padding: 10px 20px;
      font-weight: 700;
      overflow: hidden;
      position: relative;
      z-index: 1;
      display: inline-block; }
      article p.button.center a::before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 50%;
        right: 100%;
        margin: -15px 0 0 1px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #ff6200;
        -webkit-transform-origin: left;
        transform-origin: left;
        -webkit-transform: scale3d(1, 2, 1);
        transform: scale3d(1, 2, 1);
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
      article p.button.center a:hover {
        color: #ffffff; }
      article p.button.center a:hover::before {
        -webkit-transform: scale3d(20, 9, 1);
        transform: scale3d(20, 9, 1);
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out; }
  article p.link.center {
    text-align: center;
    margin-top: 30px; }
    article p.link.center a {
      text-decoration: underline;
      font-weight: 700;
      display: inline-block; }
  article .wp-block-columns {
    margin: 30px 0 60px; }
  article .wp-block-column + .wp-block-column {
    border-left: 2px solid #ff6200;
    padding-left: 40px;
    margin-left: 0; }
  article table th {
    color: #ff6200;
    text-align: left; }
  article table tr td {
    padding: 0 15px 0 0; }
  article .ourproduct {
    margin-bottom: 60px; }
    article .ourproduct .flex {
      justify-content: center; }
      article .ourproduct .flex .item {
        position: relative;
        flex: auto;
        width: 50%;
        height: 410px; }
        article .ourproduct .flex .item .caption {
          background-color: rgba(0, 0, 0, 0.7);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          color: #fff;
          padding: 40px 50px;
          opacity: 0;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
          article .ourproduct .flex .item .caption .textcaption {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            width: 100%;
            left: 0; }
            article .ourproduct .flex .item .caption .textcaption h3 {
              font-size: 35px;
              font-weight: normal;
              margin-bottom: 0;
              color: #ffffff;
              line-height: 1em; }
            article .ourproduct .flex .item .caption .textcaption p.button a {
              display: inline-block;
              border: 1px solid #fff;
              color: #fff;
              padding: 10px 15px;
              font-weight: bold;
              font-size: 17px;
              margin-top: 35px;
              overflow: hidden;
              position: relative;
              z-index: 1; }
              article .ourproduct .flex .item .caption .textcaption p.button a::before {
                content: '';
                z-index: -1;
                position: absolute;
                top: 50%;
                right: 100%;
                margin: -15px 0 0 1px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #ffffff;
                -webkit-transform-origin: left;
                transform-origin: left;
                -webkit-transform: scale3d(1, 2, 1);
                transform: scale3d(1, 2, 1);
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out; }
              article .ourproduct .flex .item .caption .textcaption p.button a:hover {
                color: #3b3b3b; }
              article .ourproduct .flex .item .caption .textcaption p.button a:hover::before {
                -webkit-transform: scale3d(20, 9, 1);
                transform: scale3d(20, 9, 1);
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out; }
        article .ourproduct .flex .item:hover .caption {
          opacity: 1;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out; }
      article .ourproduct .flex .item:nth-child(4n-1), article .ourproduct .flex .item:nth-child(5n-1) {
        height: 260px; }
  .page-template-default article p.has-text-align-left {
    max-width: 760px;
    margin: 0 auto; } }

@media all {
  article.contact {
    background: #ff6200;
    color: #ffffff;
    padding-bottom: 0; }
    article.contact h2 {
      color: #ffffff; }
    article.contact .gform_confirmation_wrapper {
      text-align: center;
      background-color: #ffffff;
      color: #ff6200;
      padding: 30px;
      display: table;
      margin: 100px auto 0; }
    article.contact .gform_wrapper {
      margin-top: 50px;
      margin-bottom: 0; }
      article.contact .gform_wrapper .gfieldset {
        border: none; }
        article.contact .gform_wrapper .gfieldset li {
          text-indent: 0; }
          article.contact .gform_wrapper .gfieldset li:before {
            display: none; }
          article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li {
            margin: 0 !important; }
            article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li:before, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li:before {
              display: none; }
            article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li input, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li input {
              width: auto !important;
              position: absolute;
              top: 0;
              left: -9999px;
              visibility: hidden; }
              article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li input + label, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li input + label {
                width: 100%;
                max-width: 100%;
                margin-left: 0;
                padding: 0px 0 0px 35px;
                font-size: 20px;
                cursor: pointer;
                line-height: 1.25em; }
                article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li input + label::before, article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li input + label::after, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li input + label::before, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li input + label::after {
                  content: '';
                  position: absolute;
                  top: 12px;
                  border-radius: 0; }
                article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li input + label::before, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li input + label::before {
                  left: 0;
                  width: 19px;
                  height: 19px;
                  margin: -10px 0 0;
                  background: transparent;
                  border: 1px solid #ffffff; }
                article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li input + label::after, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li input + label::after {
                  left: 0px;
                  width: 13px;
                  height: 13px;
                  margin: -7px 3px 0;
                  opacity: 0;
                  background: #ff6200;
                  opacity: 0;
                  filter: alpha(opacity=0);
                  -webkit-transition: all 0.2s ease-in-out 0s;
                  -moz-transition: all 0.2s ease-in-out 0s;
                  -ms-transition: all 0.2s ease-in-out 0s;
                  -o-transition: all 0.2s ease-in-out 0s;
                  transition: all 0.2s ease-in-out 0s; }
              article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li input:checked + label, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li input:checked + label {
                font-weight: normal; }
              article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li input:checked + label::after, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li input:checked + label::after {
                opacity: 1; }
          article.contact .gform_wrapper .gfieldset li ul.gfield_radio li input + label::before, article.contact .gform_wrapper .gfieldset li ul.gfield_radio li input + label::after {
            border-radius: 50%; }
          article.contact .gform_wrapper .gfieldset li ul.gfield_checkbox li input + label::after {
            background: url(./images/check-blanc.png) center center no-repeat;
            background-size: cover; }
        article.contact .gform_wrapper .gfieldset li {
          text-indent: 0;
          flex: auto;
          width: 47%;
          margin: 0 0 20px !important;
          padding: 0; }
          article.contact .gform_wrapper .gfieldset li a {
            color: #ffffff;
            border-bottom: 1px solid #ffffff; }
          article.contact .gform_wrapper .gfieldset li label {
            font-weight: normal; }
            article.contact .gform_wrapper .gfieldset li label span.gfield_required {
              color: #ffffff; }
          article.contact .gform_wrapper .gfieldset li .ginput_container {
            margin-top: 0; }
            article.contact .gform_wrapper .gfieldset li .ginput_container input, article.contact .gform_wrapper .gfieldset li .ginput_container textarea, article.contact .gform_wrapper .gfieldset li .ginput_container select {
              width: 100% !important;
              border: none;
              border-bottom: 1px solid #ffffff;
              background: transparent;
              color: #ffffff;
              padding: 10px 0 !important; }
            article.contact .gform_wrapper .gfieldset li .ginput_container input[type=file] {
              border-bottom: none; }
            article.contact .gform_wrapper .gfieldset li .ginput_container input::placeholder, article.contact .gform_wrapper .gfieldset li .ginput_container textarea::placeholder {
              color: white; }
          article.contact .gform_wrapper .gfieldset li .gfield_label {
            display: none; }
          article.contact .gform_wrapper .gfieldset li.mesure .gfield_label {
            display: inline-block; }
        article.contact .gform_wrapper .gfieldset li#field_1_31, article.contact .gform_wrapper .gfieldset li#field_8_31, article.contact .gform_wrapper .gfieldset li#field_9_31 {
          margin-right: 3% !important; }
        article.contact .gform_wrapper .gfieldset li#field_1_74, article.contact .gform_wrapper .gfieldset li#field_1_200, article.contact .gform_wrapper .gfieldset li#field_8_200, article.contact .gform_wrapper .gfieldset li#field_8_74, article.contact .gform_wrapper .gfieldset li#field_9_74, article.contact .gform_wrapper .gfieldset li#field_9_200 {
          padding-top: 100px; }
        article.contact .gform_wrapper .gfieldset li.full, article.contact .gform_wrapper .gfieldset li.arrow, article.contact .gform_wrapper .gfieldset li.choice {
          width: 100%; }
          article.contact .gform_wrapper .gfieldset li.full li, article.contact .gform_wrapper .gfieldset li.arrow li, article.contact .gform_wrapper .gfieldset li.choice li {
            width: 100%; }
        article.contact .gform_wrapper .gfieldset li.full.link {
          text-align: center; }
          article.contact .gform_wrapper .gfieldset li.full.link a {
            color: #ff6200;
            display: inline-block;
            margin-top: 45px; }
        article.contact .gform_wrapper .gfieldset li.choice {
          border: 1px solid #e0e0e0;
          padding: 30px; }
          article.contact .gform_wrapper .gfieldset li.choice > label {
            display: none; }
          article.contact .gform_wrapper .gfieldset li.choice .gfield_label {
            display: none !important; }
        article.contact .gform_wrapper .gfieldset li.mesure {
          display: flex;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          align-items: center;
          margin-bottom: 10px !important; }
          article.contact .gform_wrapper .gfieldset li.mesure .ginput_container, article.contact .gform_wrapper .gfieldset li.mesure .gfield_description {
            flex: 1;
            padding: 0;
            margin: 0 10px; }
          article.contact .gform_wrapper .gfieldset li.mesure .ginput_container input {
            padding: 0 !important;
            border-bottom: 1px solid #ff6200 !important; }
          article.contact .gform_wrapper .gfieldset li.mesure > label {
            width: 50px; }
        article.contact .gform_wrapper .gfieldset .gfield.arrow {
          position: relative; }
          article.contact .gform_wrapper .gfieldset .gfield.arrow:after {
            display: block;
            width: 21px;
            height: 11px;
            content: '';
            background: url(./images/fleche.png) center center no-repeat;
            background-size: cover;
            position: absolute;
            top: 45px;
            right: 15px;
            -webkit-transition: all 0.2s ease-in-out 0s;
            -moz-transition: all 0.2s ease-in-out 0s;
            -ms-transition: all 0.2s ease-in-out 0s;
            -o-transition: all 0.2s ease-in-out 0s;
            transition: all 0.2s ease-in-out 0s; }
          article.contact .gform_wrapper .gfieldset .gfield.arrow.selected:after {
            transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            /* Internet Explorer */
            -moz-transform: rotate(180deg);
            /* Firefox */
            -webkit-transform: rotate(180deg);
            /* Safari et Chrome */
            -o-transform: rotate(180deg);
            /* Opera */ }
        article.contact .gform_wrapper .gfieldset li.mentions {
          margin-top: 50px !important;
          font-style: italic; }
        article.contact .gform_wrapper .gfieldset li h2 {
          text-align: center;
          color: #ff6200; }
      article.contact .gform_wrapper form > .gform_body > .gfieldset {
        border: none; }
        article.contact .gform_wrapper form > .gform_body > .gfieldset > ul {
          width: 1000px;
          display: flex;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          margin: auto !important;
          list-style: none; }
          article.contact .gform_wrapper form > .gform_body > .gfieldset > ul li:before {
            display: none; }
      article.contact .gform_wrapper form > .gform_body > .gfieldset {
        border: none; }
        article.contact .gform_wrapper form > .gform_body > .gfieldset > ul {
          width: 1000px;
          margin: auto !important; }
      article.contact .gform_wrapper .gfieldset.orangebg {
        padding-bottom: 30px; }
      article.contact .gform_wrapper .gfieldset.whitebg {
        background-color: #ffffff;
        color: #3b3b3b;
        padding-top: 30px; }
        article.contact .gform_wrapper .gfieldset.whitebg ul li .ginput_container input, article.contact .gform_wrapper .gfieldset.whitebg ul li .ginput_container textarea, article.contact .gform_wrapper .gfieldset.whitebg ul li .ginput_container select {
          border-bottom: 1px solid #ff6200;
          color: #3b3b3b; }
        article.contact .gform_wrapper .gfieldset.whitebg ul.gfield_radio li input + label::before {
          border: 1px solid #ff6200; }
        article.contact .gform_wrapper .gfieldset.whitebg ul.gfield_radio li input + label::after {
          background: #ff6200; }
        article.contact .gform_wrapper .gfieldset.whitebg ul.gfield_checkbox li input + label::before {
          border: 1px solid #ff6200; }
        article.contact .gform_wrapper .gfieldset.whitebg ul.gfield_checkbox li input + label::after {
          background: url(./images/check.png) center center no-repeat;
          background-size: cover; }
        article.contact .gform_wrapper .gfieldset.whitebg .top_label .gfield_label {
          display: inline-block; }
        article.contact .gform_wrapper .gfieldset.whitebg h3 {
          margin-left: -20px;
          font-weight: bold;
          font-size: 22px;
          margin-top: 30px;
          margin-bottom: 15px; }
      article.contact .gform_wrapper .gfieldset.flex .leftalign, article.contact .gform_wrapper .gfieldset.flex .rightalign {
        flex: 1; }
      article.contact .gform_wrapper .gfieldset.flex .rightalign {
        float: right; }
      article.contact .gform_wrapper .field_description_below .gfield_description {
        padding-top: 5px; }
      article.contact .gform_wrapper form > .gform_body .gfieldset.flex.wrap {
        max-width: 1000px;
        width: 100%;
        margin: auto !important;
        display: block;
        padding: 0; }
        article.contact .gform_wrapper form > .gform_body .gfieldset.flex.wrap > .rightalign, article.contact .gform_wrapper form > .gform_body .gfieldset.flex.wrap > .leftalign {
          width: 47%;
          vertical-align: top;
          display: inline-block; }
      article.contact .gform_wrapper form > .gform_body .gfieldset.wrap.withborder {
        display: none;
        border: 1px solid #e0e0e0;
        width: 1000px;
        margin: 30px auto 0 !important;
        padding: 50px 30px; }
        article.contact .gform_wrapper form > .gform_body .gfieldset.wrap.withborder ul {
          width: auto; }
      article.contact .gform_wrapper .gform_footer {
        background-color: #ffffff;
        margin: 0;
        text-align: center;
        padding-bottom: 100px;
        padding-top: 80px; }
        article.contact .gform_wrapper .gform_footer input[type="submit"] {
          border: 1px solid #ff6200;
          padding: 10px 20px;
          font-weight: 700;
          display: inline-block;
          background-color: #ffffff;
          color: #ff6200;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          cursor: pointer; }
          article.contact .gform_wrapper .gform_footer input[type="submit"]:hover {
            background-color: #ff6200;
            color: #ffffff;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out; }
      article.contact .gform_wrapper li.gfield.reponse48h {
        width: 100%;
        margin: 60px 0 0 !important;
        text-align: center;
        font-size: 25px; }
      article.contact .gform_wrapper li.gfield_error input[type=text], article.contact .gform_wrapper li.gfield_error input[type=url], article.contact .gform_wrapper li.gfield_error input[type=email], article.contact .gform_wrapper li.gfield_error input[type=tel], article.contact .gform_wrapper li.gfield_error input[type=number], article.contact .gform_wrapper li.gfield_error input[type=password], article.contact .gform_wrapper li.gfield_error textarea, article.contact .gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), article.contact .gform_wrapper li.gfield_error textarea {
        border: none;
        border-bottom: 1px solid #c90043; }
      article.contact .gform_wrapper .gfield_error .gfield_label {
        color: #c90043; }
      article.contact .gform_wrapper .validation_message {
        padding-top: 5px;
        padding-right: 0;
        text-align: right;
        color: #c90043; }
      article.contact .gform_wrapper li.gfield.gfield_error, article.contact .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
        background-color: transparent;
        border-top: none;
        border-bottom: none; }
      article.contact .gform_wrapper div.validation_error {
        display: none; } }

.wrap.mentions {
  max-width: 1000px;
  padding-bottom: 90px;
  font-size: .875em; }

@font-face {
  font-family: 'lg';
  src: url("../ocea-fdc/fonts/lg.eot?n1z373");
  src: url("../ocea-fdc/fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../ocea-fdc/fonts/lg.woff?n1z373") format("woff"), url("../ocea-fdc/fonts/lg.ttf?n1z373") format("truetype"), url("../ocea-fdc/fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal; }

@media all {
  .lg-icon {
    font-family: 'lg';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .lg-actions .lg-next, .lg-actions .lg-prev {
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 2px;
    color: #999;
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-top: -10px;
    padding: 8px 10px 9px;
    position: absolute;
    top: 50%;
    z-index: 1080;
    border: none;
    outline: none; }
  .lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #FFF; }
  .lg-actions .lg-next {
    right: 20px; }
  .lg-actions .lg-next:before {
    content: "\e095"; }
  .lg-actions .lg-prev {
    left: 20px; }
  .lg-actions .lg-prev:after {
    content: "\e094"; }
  .lg-download, .lg-thumb-outer {
    display: none; }
  .lg-outer.lg-right-end .lg-object {
    -webkit-animation: lg-right-end 0.3s;
    -o-animation: lg-right-end 0.3s;
    animation: lg-right-end 0.3s;
    position: relative; }
  .lg-outer.lg-left-end .lg-object {
    -webkit-animation: lg-left-end 0.3s;
    -o-animation: lg-left-end 0.3s;
    animation: lg-left-end 0.3s;
    position: relative; }
  .lg-toolbar {
    z-index: 1082;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.45); }
  .lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear; }
  .lg-toolbar .lg-icon:hover {
    color: #FFF; }
  .lg-toolbar .lg-close:after {
    content: "\e070"; }
  .lg-toolbar, .lg-prev, .lg-next {
    opacity: 1;
    -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
    -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
    -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
    transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }
  .lg-hide-items .lg-prev {
    opacity: 0;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  .lg-hide-items .lg-next {
    opacity: 0;
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  .lg-hide-items .lg-toolbar {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  .lg-outer.lg-can-toggle .lg-item {
    padding-bottom: 0; }
  .lg-outer.lg-pull-caption-up .lg-sub-html {
    -webkit-transition: bottom 0.25s ease;
    -o-transition: bottom 0.25s ease;
    transition: bottom 0.25s ease; }
  .lg-outer .lg-video-cont {
    display: inline-block;
    vertical-align: middle;
    max-width: 1140px;
    max-height: 100%;
    width: 100%;
    padding: 0 5px; }
  .lg-outer .lg-video {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative; }
  .lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer; }
  .lg-outer .lg-has-iframe .lg-video {
    -webkit-overflow-scrolling: touch;
    overflow: auto; }
  .lg-outer .lg-has-vimeo .lg-video-play {
    background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent; }
  .lg-outer .lg-has-vimeo:hover .lg-video-play {
    background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent; }
  .lg-outer .lg-has-html5 .lg-video-play {
    background: transparent url("../img/video-play.png") no-repeat scroll 0 0;
    height: 64px;
    margin-left: -32px;
    margin-top: -32px;
    width: 64px;
    opacity: 0.8; }
  .lg-outer .lg-has-html5:hover .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-has-youtube .lg-video-play {
    background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent; }
  .lg-outer .lg-has-youtube:hover .lg-video-play {
    background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent; }
  .lg-outer .lg-video-object {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0; }
  .lg-outer .lg-has-video .lg-video-object {
    visibility: hidden; }
  .lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
    display: none; }
  .lg-outer .lg-has-video.lg-video-playing .lg-video-object {
    visibility: visible; }
  .lg-progress-bar {
    background-color: #333;
    height: 5px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1083;
    opacity: 0;
    -webkit-transition: opacity 0.08s ease 0s;
    -moz-transition: opacity 0.08s ease 0s;
    -o-transition: opacity 0.08s ease 0s;
    transition: opacity 0.08s ease 0s; }
  .lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0; }
  .lg-progress-bar.lg-start .lg-progress {
    width: 100%; }
  .lg-show-autoplay .lg-progress-bar {
    opacity: 1; }
  .lg-autoplay-button:after {
    content: "\e01d"; }
  .lg-show-autoplay .lg-autoplay-button:after {
    content: "\e01a"; }
  .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
    -webkit-transition-duration: 0s;
    transition-duration: 0s; }
  .lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
    -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
    -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
    -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
    -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden; }
  .lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
    -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
    -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
    transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden; }
  #lg-zoom-in:after {
    content: "\e311"; }
  #lg-actual-size {
    font-size: 20px; }
  #lg-actual-size:after {
    content: "\e033"; }
  #lg-zoom-out {
    opacity: 0.5;
    pointer-events: none; }
  #lg-zoom-out:after {
    content: "\e312"; }
  .lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto; }
  .lg-outer .lg-pager-outer {
    bottom: 60px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 1080;
    height: 10px; }
  .lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible; }
  .lg-outer .lg-pager-cont {
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    margin: 0 5px; }
  .lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    box-shadow: 0 0 0 2px white inset; }
  .lg-outer .lg-pager-thumb-cont {
    background-color: #fff;
    color: #FFF;
    bottom: 100%;
    height: 83px;
    left: 0;
    margin-bottom: 20px;
    margin-left: -60px;
    opacity: 0;
    padding: 5px;
    position: absolute;
    width: 120px;
    border-radius: 3px;
    -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
    -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
    transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  .lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%; }
  .lg-outer .lg-pager {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
    display: block;
    height: 12px;
    -webkit-transition: box-shadow 0.3s ease 0s;
    -o-transition: box-shadow 0.3s ease 0s;
    transition: box-shadow 0.3s ease 0s;
    width: 12px; }
  .lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
    box-shadow: 0 0 0 8px white inset; }
  .lg-outer .lg-caret {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px dashed;
    bottom: -10px;
    display: inline-block;
    height: 0;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    vertical-align: middle;
    width: 0; }
  .lg-fullscreen, .lg-zoom-in, .lg-zoom-out, .lg-actual-size, .lg-share {
    display: none; }
  .lg-outer #lg-dropdown-overlay {
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    cursor: default;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1081;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
    -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
    transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }
  .lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -moz-transform: translate3d(0, 0px, 0);
    -o-transform: translate3d(0, 0px, 0);
    -ms-transform: translate3d(0, 0px, 0);
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
    opacity: 1;
    visibility: visible; }
  .lg-outer .lg-dropdown {
    background-color: #fff;
    border-radius: 2px;
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 50px;
    opacity: 0;
    visibility: hidden;
    -moz-transform: translate3d(0, 5px, 0);
    -o-transform: translate3d(0, 5px, 0);
    -ms-transform: translate3d(0, 5px, 0);
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
    -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
    -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
    -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
    transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; }
  .lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #FFF;
    right: 16px;
    top: -16px; }
  .lg-outer .lg-dropdown > li:last-child {
    margin-bottom: 0px; }
  .lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
    color: #333; }
  .lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px; }
  .lg-outer .lg-dropdown a:hover {
    background-color: rgba(0, 0, 0, 0.07); }
  .lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle; }
  .lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto; }
  .lg-group:after {
    content: "";
    display: table;
    clear: both; }
  .lg-outer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    text-align: left;
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .lg-outer.lg-visible {
    opacity: 1; }
  .lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important; }
  .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1; }
  .lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%; }
  .lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap; }
  .lg-outer .lg-item {
    background: url("../img/loading.gif") no-repeat scroll center center transparent;
    display: none !important; }
  .lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important; }
  .lg-outer.lg-css .lg-current {
    display: inline-block !important; }
  .lg-outer .lg-item, .lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%; }
  .lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px; }
  .lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .lg-outer .lg-item.lg-complete {
    background-image: none; }
  .lg-outer .lg-item.lg-current {
    z-index: 1060; }
  .lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important; }
  .lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-empty-html {
    display: none; }
  .lg-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-backdrop.in {
    opacity: 1; }
  .lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
    -webkit-transition: none 0s ease 0s !important;
    -moz-transition: none 0s ease 0s !important;
    -o-transition: none 0s ease 0s !important;
    transition: none 0s ease 0s !important; }
  .lg-css3.lg-use-css3 .lg-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden; }
  .lg-css3.lg-use-left .lg-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden; }
  .lg-css3.lg-fade .lg-item {
    opacity: 0; }
  .lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1; }
  .lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    -moz-transition: opacity 0.1s ease 0s;
    -o-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item {
    opacity: 0; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }
  .lg-css3.lg-slide.lg-use-left .lg-item {
    opacity: 0;
    position: absolute;
    left: 0; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; } }

@media screen and (max-width: 1665px) {
  .wrap, #presentation .wrap {
    padding: 0 30px; }
  header {
    padding: 20px 15px; }
    header .wrap {
      padding: 0; } }

@media screen and (max-width: 1400px) {
  header .mainmenu ul.nav-menu > li + li {
    margin-left: 50px; }
    header .mainmenu ul.nav-menu > li + li a::before {
      left: -25px; } }

@media screen and (max-width: 1250px) {
  article .wrap .imagetexte.flex .texte {
    margin-left: 30px; }
  section#product .numbers .blocnumber .flex .item + .item {
    padding-left: 90px; }
    section#product .numbers .blocnumber .flex .item + .item::before {
      width: 90px; }
  header .mainmenu ul.nav-menu > li + li {
    margin-left: 34px; }
    header .mainmenu ul.nav-menu > li + li a {
      font-size: 17px; }
      header .mainmenu ul.nav-menu > li + li a::before {
        left: -17px; }
  header .language .wpml-ls-legacy-dropdown {
    width: 80px; }
    header .language .wpml-ls-legacy-dropdown li.wpml-ls-slot-languages a.js-wpml-ls-item-toggle span.wpml-ls-native {
      font-size: 17px; } }

@media screen and (max-width: 1050px) {
  header .language {
    margin-right: 10px; }
  article.contact .gform_wrapper form > .gform_body > .gfieldset > ul, article.contact .gform_wrapper form > .gform_body .gfieldset.wrap.withborder, article.contact .gform_wrapper form > .gform_body .gfieldset.flex.wrap {
    width: 100%; }
  .others .item, article .ourproduct .flex .item {
    flex: auto;
    width: 50%; }
  article.contact .gform_wrapper .gfieldset.orangebg, article.contact .gform_wrapper .gfieldset.whitebg {
    width: 100%;
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px; }
  footer .flex #menu-footer ul li {
    display: block; }
    footer .flex #menu-footer ul li + li a {
      padding-left: 0;
      margin-left: 0; }
      footer .flex #menu-footer ul li + li a::before {
        display: none; }
  .menu-trigger {
    display: block; }
  .mainmenu {
    display: none; }
  body {
    font: 18px/26px "Calibri", sans-serif; }
  section#product .flex .item .caption .textcaption h3 {
    font-size: 25px;
    margin-bottom: 10px; }
  article .wrap h2 {
    font-size: 25px; }
  article .wrap h3 {
    font-size: 23px; }
  article .wrap .wp-block-columns {
    flex-wrap: wrap; }
    article .wrap .wp-block-columns .wp-block-column {
      flex-basis: 100% !important; }
      article .wrap .wp-block-columns .wp-block-column + .wp-block-column {
        border-top: 2px solid #ff6200;
        border-left: none;
        padding-top: 40px;
        margin-top: 30px;
        padding-left: 0px; }
  article .wrap .imagetexte.flex .images, article .wrap .imagetexte.flex .texte {
    flex: auto;
    width: 100%;
    text-align: center; }
  article .wrap .imagetexte.flex .images {
    margin-left: auto;
    margin-right: auto; }
  article .wrap .imagetexte.flex .texte {
    margin-left: 0;
    margin-top: 30px;
    text-align: left; }
  article .wrap .img-rightalign.imagetexte.flex .images {
    order: 1; }
  article .wrap .img-rightalign.imagetexte.flex .texte {
    order: 2;
    margin-right: 0; }
  article .wrap .flexslider.slidersmall .flex-viewport .slides li {
    width: 55px !important;
    margin-right: 5px !important; }
  article table th, article table tr {
    vertical-align: top;
    line-height: 1.125em; }
  article table tr td {
    padding-right: 10px; } }

@media all and (max-width: 850px) {
  article.contact .gform_wrapper form > .gform_body .gfieldset.flex.wrap > .leftalign, article.contact .gform_wrapper form > .gform_body .gfieldset.flex.wrap > .rightalign {
    width: 100%; }
  article.contact .gform_wrapper .gfieldset li#field_1_31 {
    margin-right: 0 !important; }
  article.contact .gform_wrapper .gfieldset h2 {
    margin-bottom: 0px !important; }
  article.contact .gform_wrapper .gfieldset li#field_1_74, article.contact .gform_wrapper .gfieldset li#field_1_200, article.contact .gform_wrapper .gfieldset li#field_8_200, article.contact .gform_wrapper .gfieldset li#field_8_74, article.contact .gform_wrapper .gfieldset li#field_9_74, article.contact .gform_wrapper .gfieldset li#field_9_200 {
    padding-top: 0px; }
  article.contact .gform_wrapper ul li.gfield.gfield_html img {
    width: 100%; }
  section#product .numbers {
    margin-top: 60px !important; }
    section#product .numbers .blocnumber .flex .item {
      flex: auto;
      width: 50%;
      margin: 30px 0;
      padding-left: 90px; }
      section#product .numbers .blocnumber .flex .item::before {
        content: '';
        width: 90px;
        height: 2px;
        background-color: #ff6200;
        position: absolute;
        top: 15px;
        left: 0;
        z-index: -1; }
      section#product .numbers .blocnumber .flex .item:last-child {
        margin-bottom: 0; }
  article.contact .gform_wrapper .gfieldset li {
    width: 97%; }
  .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
    width: auto; } }

@media all and (max-width: 640px) {
  #presentation .wrap .accordeon_content .line {
    flex-flow: column; }
    #presentation .wrap .accordeon_content .line .img {
      min-height: 300px; }
    #presentation .wrap .accordeon_content .line .text {
      padding: 30px 0 0 0; }
  #presentation .wrap .accordeon_content .line:nth-of-type(2n) {
    flex-flow: column; }
  article.contact p {
    padding: 0 30px; } }

@media all and (max-width: 550px) {
  section#product .numbers .blocnumber {
    margin-top: 0; }
  section#product .numbers .blocnumber .flex .item {
    padding-left: 0;
    width: 100%; }
    section#product .numbers .blocnumber .flex .item::before {
      display: none; }
    section#product .numbers .blocnumber .flex .item + .item {
      padding-left: 0; }
  .others .item, article .ourproduct .flex .item {
    width: 100%; }
  .bandeau .maintitle h1 {
    font-size: 25px; }
  .prefooter {
    padding: 65px 0 50px; }
    .prefooter h2 {
      font-size: 28px;
      margin-bottom: 20px; }
    .prefooter #lightgallery.flex li + li {
      margin-left: 10px; }
  section#product h2 {
    font-size: 28px; }
  article {
    padding: 60px 0; }
  article table th, article table tr td {
    font-size: .875em; }
  article .wrap .imagetexte.flex {
    margin-bottom: 30px; }
  footer .flex > * {
    flex: auto;
    width: 100%;
    text-align: center; }
  footer .flex #IC {
    margin-top: 30px; } }

@media all and (max-width: 480px) {
  #presentation, section#product, .prefooter {
    padding: 60px 0; }
  .wrap, #presentation .wrap {
    padding: 0 15px; }
  header {
    padding: 20px 15px; }
    header .wrap {
      padding: 0; }
  section#product .flex .item .caption, article .ourproduct .flex .item .caption {
    padding: 30px; }
    section#product .flex .item .caption .textcaption p, article .ourproduct .flex .item .caption .textcaption p {
      line-height: 1.125em; }
    section#product .flex .item .caption .textcaption p.button, article .ourproduct .flex .item .caption .textcaption p.button {
      margin-top: 15px; }
  article .wrap .imagetexte.flex, article .wrap article .wrap h2:first-child {
    margin-bottom: 45px; }
  article .wrap h3 {
    margin-bottom: 15px; }
  article .wrap * + h2 {
    margin-top: 30px; }
  article.contact .gform_wrapper .gfieldset.orangebg, article.contact .gform_wrapper .gfieldset.whitebg {
    padding-left: 15px;
    padding-right: 15px; }
  article.contact .gform_wrapper .gfieldset li.choice {
    padding: 15px 30px; } }

/************************** GENERIC  ****************************************/
* {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none; }


@media all {
	.others {
		@include shadow ;
		.item {
			position: relative;
			flex: 1;
			> img {
				height: 330px;
				object-fit: cover;
				width: 100%;
			}
			.caption {
				background-color: rgba(0, 0, 0, 0.7);
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				color: #fff;
				padding: 40px 50px;
				opacity: 0;
				@include smoothanimation ;
				.textcaption {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					text-align: center;
					width: 100%;
					left: 0;
					h3 {
						font-size: 35px; font-weight: normal;
						line-height:1.125em;
					}
					p.button a {
						display: inline-block;
						border: 1px solid #fff;
						color: #fff;
						padding: 10px 15px;
						font-weight: bold;
						font-size: 17px;
						margin-top: 35px;
						overflow: hidden;
						position: relative;
						z-index: 1;
						&::before {
							content: '';
							z-index: -1;
							position: absolute;
							top: 50%;
							right: 100%;
							margin: -15px 0 0 1px;
							width: 30px;
							height: 30px;
							border-radius: 50%;
							background:$white ;
							-webkit-transform-origin: left;
							transform-origin: left;
							-webkit-transform: scale3d(1,2,1);
							transform: scale3d(1,2,1);
							@include smoothanimation ;
						}
						&:hover {
							color :$grey ;
						}
						&:hover::before {
							-webkit-transform: scale3d(20,9,1);
							transform: scale3d(20,9,1);
							@include smoothanimation ;
						}
					}
				}
			}
			&:hover .caption {
				opacity: 1;
				@include smoothanimation ;
			}
		}
	}

	article p.button.center {
		text-align: center;
		margin-top: 30px;
		display: inline-block;
		width: 100%;
		a {
			border: 1px solid $orange;
			padding: 10px 20px;
			font-weight: 700;
			overflow: hidden;
			position: relative;
			z-index: 1;
			display: inline-block;
			&::before {
				content: '';
				z-index: -1;
				position: absolute;
				top: 50%;
				right: 100%;
				margin: -15px 0 0 1px;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background:$orange ;
				-webkit-transform-origin: left;
				transform-origin: left;
				-webkit-transform: scale3d(1,2,1);
				transform: scale3d(1,2,1);
				@include smoothanimation ;
			}
			&:hover {
				color :$white ;
			}
			&:hover::before {
				-webkit-transform: scale3d(20,9,1);
				transform: scale3d(20,9,1);
				@include smoothanimation ;
			}
		}
	}

	article p.link.center {
		text-align: center;
		margin-top: 30px;
		a {
			// border-bottom: 1px solid $orange;
			text-decoration:underline;
			font-weight: 700;
			display: inline-block;
		}
	}

	article .wp-block-columns {
		margin: 30px 0 60px;
	}

	article .wp-block-column + .wp-block-column {
		border-left: 2px solid $orange;
		padding-left: 40px;
		margin-left: 0;
	}

	article {
		table {
			th {
				color: $orange;
				text-align: left;
			}
			tr {
				td {padding:0 15px 0 0;}
			}
		}
	}

	article .ourproduct {
		margin-bottom:60px;
		.flex {
			justify-content: center;
			.item {
				position: relative;
				flex: auto;
				width: 50%;
				height:410px;
				// > img {
				// 	height: 410px;
				// 	object-fit: cover;
				// 	width: 100%;
				// }
				.caption {
					background-color: rgba(0, 0, 0, 0.7);
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					color: #fff;
					padding: 40px 50px;
					opacity: 0;
					@include smoothanimation ;
					.textcaption {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						text-align: center;
						width: 100%;
						left: 0;
						h3 {
							font-size: 35px;
							font-weight: normal;
							margin-bottom:0;
							color: $white;
							line-height:1em;
						}
						p.button a {
							display: inline-block;
							border: 1px solid #fff;
							color: #fff;
							padding: 10px 15px;
							font-weight: bold;
							font-size: 17px;
							margin-top: 35px;
							overflow: hidden;
							position: relative ;
							z-index: 1;
							&::before {
								content: '';
								z-index: -1;
								position: absolute;
								top: 50%;
								right: 100%;
								margin: -15px 0 0 1px;
								width: 30px;
								height: 30px;
								border-radius: 50%;
								background:$white ;
								-webkit-transform-origin: left;
								transform-origin: left;
								-webkit-transform: scale3d(1,2,1);
								transform: scale3d(1,2,1);
								@include smoothanimation ;
							}
							&:hover {
								color :$grey ;
							}
							&:hover::before {
								-webkit-transform: scale3d(20,9,1);
								transform: scale3d(20,9,1);
								@include smoothanimation ;
							}
						}
					}
				}
				&:hover .caption {
					opacity: 1;
					@include smoothanimation ;
				}
			}
			// .item:nth-child(4n-1) > img, .item:nth-child(5n-1) > img {
			.item:nth-child(4n-1), .item:nth-child(5n-1) {
				height: 260px;
			}
		}
	}

	// Spécifique 

	.page-template-default {
		article {
			p.has-text-align-left {max-width: 760px; margin:0 auto;}
		}
	}
}
/****** IMPORT PARTS SASS ********/

@import "variables";
@import "pages/layout";
@import "pages/page-home";
@import "pages/menu";
@import "pages/page";
@import "pages/page-contact";
@import "pages/lightgallery";
@import "responsive";

/************************** GENERIC  ****************************************/

* {
	margin: 0;
	padding: 0;
	-webkit-text-size-adjust: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
}

@media all {
	article.contact {
		background: $orange;
		color: $white;
		padding-bottom: 0;
		h2 {
			color: $white;
		}
		.gform_confirmation_wrapper {
			text-align :center ;
			background-color :$white;
			color :$orange ;
			padding : 30px;
			display :table ;
			margin : 100px auto 0;
		}
		.gform_wrapper {
			margin-top: 50px;
			margin-bottom: 0;
			.gfieldset {
				border: none;
				li {
					text-indent: 0;
					&:before {display:none;}
					ul.gfield_checkbox, ul.gfield_radio {
						li {
							margin: 0 !important;
							&:before {display:none;}
							input {
								width: auto !important;
								position: absolute;
								top: 0;
								left: -9999px;
								visibility: hidden;
								+ label {
									width:100%; max-width:100%;
									margin-left: 0;
									padding: 0px 0 0px 35px;
									font-size: 20px;
									cursor: pointer;
									line-height:1.25em;
									&::before, &::after {
										content: '';
										position: absolute;
										top:12px;
										border-radius: 0;
									}
									&::before {
										left: 0;
										width: 19px;
										height: 19px;
										margin: -10px 0 0;
										background: transparent;
										border: 1px solid $white;
									}
									&::after {
										left: 0px;
										width: 13px;
										height: 13px;
										margin: -7px 3px 0;
										opacity: 0;
										background: $orange;
										@include opacity(0);
										@include animate(.2s);
										// -webkit-transform: translate3d(-40px, 0, 0) scale(.5);
										// transform: translate3d(-40px, 0, 0) scale(.5);
										// transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
										// transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
										// transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
									}
								}
								&:checked + label {
									font-weight: normal;
								}
								&:checked + label::after {
									// -webkit-transform: translate3d(0, 0, 0);
									// transform: translate3d(0, 0, 0);
									opacity: 1;
								}
							}
						}
					}
					ul.gfield_radio li input + label::before, ul.gfield_radio li input + label::after {
						border-radius: 50%;
					}
					ul.gfield_checkbox {
						li input + label::after {
							background: url(./images/check-blanc.png) center center no-repeat; background-size: cover;
						}
					}
				}
				li {
					text-indent: 0;
					flex: auto;
					width: 47%;
					margin: 0 0 20px !important;
					padding: 0;
					a {
						color: $white;
						border-bottom: 1px solid $white;
					}
					label {
						font-weight: normal;
						span.gfield_required {
							color: $white;
						}
					}
					.ginput_container {
						margin-top: 0;
						input, textarea, select {
							width: 100% !important;
							border: none;
							border-bottom: 1px solid $white;
							background: transparent;
							color: $white;
							padding: 10px 0 !important;
						}
						input[type=file] {
							border-bottom: none;
						}
						input::placeholder, textarea::placeholder {color:white;}
					}
					// .field_sublabel_below {
					// 	label {display:none;}
					// }
					.gfield_label {display:none;}
					&.mesure .gfield_label {display:inline-block;}
				}
				li#field_1_31, li#field_8_31, li#field_9_31 {
					margin-right:3% !important;
				}
				li#field_1_74, li#field_1_200, li#field_8_200, li#field_8_74, li#field_9_74, li#field_9_200 {padding-top:100px;}
				li.full, li.arrow, li.choice {
					width: 100%;
					li {width: 100%;}
				}
				li.full.link {
					text-align:center;
					a {color:$orange; display:inline-block; margin-top:45px;}
				}
				li.choice {
					border: 1px solid #e0e0e0;
					padding: 30px;
					> label {display: none;}
					.gfield_label {display:none !important;}
				}
				li.mesure {
					display: flex;
					display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;
					-ms-flex-wrap: wrap;
					flex-wrap: wrap;
					-webkit-flex-wrap: wrap;
					align-items: center;
					margin-bottom: 10px !important;
					.ginput_container, .gfield_description {
						flex: 1;
						padding: 0;
						margin: 0 10px;
					}
					.ginput_container input {
						padding: 0 !important;
						border-bottom: 1px solid $orange !important;
					}
					> label {width: 50px;}
				}
				.gfield.arrow {
					position:relative;
					&:after {
						display:block;
						width:21px; height:11px; content:'';
						background: url(./images/fleche.png) center center no-repeat; background-size: cover;
						position:absolute; top:45px; right:15px;
						@include animate(0.2s);
					}
					&.selected {
						&:after {
							@include rotate(180deg);
						}
					}
				}
				li.mentions {
					margin-top: 50px !important ;
					font-style: italic ;
				}
				li h2 {
					text-align: center;
					color: $orange;
				}
			}

			form > .gform_body > .gfieldset {
				border: none;
				> ul {
					width: 1000px;
					display: flex;
					display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;
					flex-wrap: wrap;
					-webkit-flex-wrap: wrap;
					margin: auto !important;
					list-style:none;
					li:before {display:none;}
				}
			}

			form > .gform_body > .gfieldset {
				border: none;
				> ul {
					width: 1000px;
					margin: auto !important;
				}
			}

			.gfieldset.orangebg {
				padding-bottom:30px;
			}
			.gfieldset.whitebg {
				background-color:$white;
				color:$grey; padding-top:30px;
				ul li .ginput_container input, ul li .ginput_container textarea, ul li .ginput_container select {
					border-bottom: 1px solid $orange;
					color: $grey;
				}
				ul.gfield_radio {
					li input + label::before {border: 1px solid $orange;}
					li input + label::after {
						background: $orange;
						// background: url(./images/check.png) center center no-repeat; background-size: cover;
					}
				}
				ul.gfield_checkbox {
					li input + label::before {border: 1px solid $orange;}
					li input + label::after {
						//background: $orange;
						background: url(./images/check.png) center center no-repeat; background-size: cover;
					}
				}
				.top_label .gfield_label {display:inline-block;}
				h3 {
					margin-left:-20px;
					font-weight:bold;
					font-size:22px;
					margin-top:30px; margin-bottom: 15px;
				}
			}

			.gfieldset.flex .leftalign, .gfieldset.flex .rightalign {flex:1;}

			.gfieldset.flex .rightalign {float:right;}

			.field_description_below .gfield_description {padding-top:5px;}

			form > .gform_body .gfieldset.flex.wrap {
				max-width:1000px;
				width:100%;
				margin: auto !important;
				display: block;
				padding: 0;
				> .rightalign, > .leftalign {
					width: 47%;
					vertical-align: top;
					display: inline-block;
				}
			}
			form > .gform_body .gfieldset.wrap.withborder {
				display:none;
				border: 1px solid #e0e0e0;
				width: 1000px;
				margin: 30px auto 0 !important;
				padding: 50px 30px;
				ul {width: auto;}
			}
			.gform_footer {
				background-color: $white;
				margin: 0;
				text-align: center;
				padding-bottom: 100px;
				padding-top: 80px;
				input[type="submit"] {
					border: 1px solid $orange;
					padding: 10px 20px;
					font-weight: 700;
					display: inline-block;
					background-color: $white;
					color: $orange;
					@include smoothanimation ;
					cursor: pointer;
					&:hover {
						background-color :$orange ;
						color:$white ;
						@include smoothanimation ;
					}
				}
			}
			li.gfield.reponse48h {
				width:100%; margin:60px 0 0 !important;
				text-align: center; font-size:25px;
			}
			li.gfield_error input[type=text], li.gfield_error input[type=url], li.gfield_error input[type=email], li.gfield_error input[type=tel], li.gfield_error input[type=number], li.gfield_error input[type=password], li.gfield_error textarea, li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), li.gfield_error textarea {
				border: none;
				border-bottom: 1px solid #c90043;
			}
			.gfield_error .gfield_label {color :#c90043 ;}
			.validation_message {padding-top : 5px; padding-right : 0 ; text-align :right ;color :#c90043 ; }
			li.gfield.gfield_error, li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
				background-color: transparent ;border-top: none ; border-bottom : none ;
			}
			div.validation_error {display : none ;}
		}
	}
}

.wrap.mentions {
	max-width:1000px; padding-bottom:90px;
	font-size: .875em;
}

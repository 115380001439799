@media screen and (max-width: 1665px) {
  // article .wrap, .wrap {
  // 	width: 96%;
  // }
  // article .wrap, #presentation .wrap {
  .wrap, #presentation .wrap {
    padding: 0 30px;
  }
  header {
    padding: 20px 15px;
    .wrap {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1400px) {

  header {
    .mainmenu ul.nav-menu > li + li {
      margin-left: 50px;
      a {
        &::before {
          left: -25px;
        }
      }
    }
  }
}


@media screen and (max-width: 1250px) {
  article .wrap .imagetexte.flex {
    .texte {
      margin-left: 30px;
    }
  }
  section#product .numbers .blocnumber .flex .item + .item {
    padding-left: 90px;
    &::before {
      width: 90px;
    }
  }
  header {

    .mainmenu ul.nav-menu > li + li {
      margin-left: 34px;
      a {
        font-size : 17px;
        &::before {
          left: -17px;
        }
      }
    }
    .language .wpml-ls-legacy-dropdown{
      width : 80px;
      li.wpml-ls-slot-languages a.js-wpml-ls-item-toggle span.wpml-ls-native {
        font-size : 17px;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  header .language {
    margin-right : 10px ;
  }
  article.contact .gform_wrapper form > .gform_body > .gfieldset > ul, article.contact .gform_wrapper form > .gform_body .gfieldset.wrap.withborder, article.contact .gform_wrapper form > .gform_body .gfieldset.flex.wrap {
    width: 100%;
  }
  .others .item, article .ourproduct .flex .item {
    flex: auto;
    width: 50%;
  }
  article.contact {
    .gform_wrapper {
      .gfieldset.orangebg, .gfieldset.whitebg {
        width: 100%;
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  footer .flex #menu-footer ul li {
    display: block;
    + li a {
      padding-left: 0;
      margin-left: 0;
      &::before {
        display: none;
      }
    }
  }
  .menu-trigger {
    display: block;
  }
  .mainmenu {
    display: none;
  }
  body {
    font: 18px/26px "Calibri", sans-serif;
  }
  section#product .flex .item .caption .textcaption {
    h3 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    // p.button{margin-top:15px;}
  }
  article .wrap {
    h2 {
      font-size: 25px;
    }
    h3 {
      font-size: 23px;
    }
    .wp-block-columns {
      flex-wrap: wrap;
      .wp-block-column {
        flex-basis: 100% !important;
        + .wp-block-column {
          border-top: 2px solid $orange;
          border-left: none;
          padding-top: 40px;
          margin-top: 30px;
          padding-left: 0px;
        }
      }
    }
    .imagetexte.flex {
      .images, .texte {
        flex: auto;
        width: 100%;
        text-align: center;
      }
      .images {
        margin-left: auto;
        margin-right: auto;
      }
      .texte {
        margin-left: 0;
        margin-top: 30px;
        text-align: left;
      }
    }
    .img-rightalign.imagetexte.flex {
      .images {
        order: 1;
      }
      .texte {
        order: 2;
        margin-right: 0;
      }
    }
    .flexslider.slidersmall .flex-viewport .slides li {
      width: 55px !important;
      margin-right: 5px !important;
    }
  }
  article table {
    th, tr {
      vertical-align: top;
      line-height: 1.125em;
    }
    tr td {
      padding-right: 10px;
    }
  }
}

@media all and (max-width: 850px) {
  // #presentation .wrap h1 {font-size:23px;}
  article.contact .gform_wrapper form > .gform_body .gfieldset.flex.wrap > .leftalign, article.contact .gform_wrapper form > .gform_body .gfieldset.flex.wrap > .rightalign {
    width: 100%;
  }
  article.contact .gform_wrapper {
    .gfieldset {
      li#field_1_31 {
        margin-right: 0 !important;
      }
      h2 {margin-bottom: 0px !important;}
      li#field_1_74, li#field_1_200, li#field_8_200, li#field_8_74, li#field_9_74, li#field_9_200 {padding-top:0px;}
    }
    ul li.gfield.gfield_html img {
      width: 100%;
    }
  }
  section#product .numbers {
    margin-top: 60px !important;
    .blocnumber .flex .item {
      flex: auto;
      width: 50%;
      margin: 30px 0;
      padding-left: 90px;
      &::before {
        content: '';
        width: 90px;
        height: 2px;
        background-color: $orange;
        position: absolute;
        top: 15px;
        left: 0;
        z-index: -1;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  article.contact .gform_wrapper .gfieldset li {
    width: 97%;
  }
  .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
    width: auto;
  }
}

@media all and (max-width: 640px) {
  #presentation .wrap .accordeon_content {
    .line {
      flex-flow: column;
      .img {
        min-height: 300px;
      }
      .text {
        padding: 30px 0 0 0;
      }
    }
    .line:nth-of-type(2n) {
      flex-flow: column;
    }
  }
  article.contact p {
    padding: 0 30px;
  }
}

@media all and (max-width: 550px) {
  section#product .numbers .blocnumber {
    margin-top: 0;
  }
  section#product .numbers .blocnumber .flex .item {
    padding-left: 0;
    width: 100%;
    &::before {
      display: none;
    }
    + .item {
      padding-left: 0;
    }
  }
  .others .item, article .ourproduct .flex .item {
    width: 100%;
  }
  .bandeau .maintitle h1 {
    font-size: 25px;
  }
  .prefooter {
    padding: 65px 0 50px;
    h2 {
      font-size: 28px;
      margin-bottom: 20px;
    }
    #lightgallery.flex li + li {
      margin-left: 10px;
    }
  }
  section#product h2 {
    font-size: 28px;
  }
  article {
    padding: 60px 0;
  }
  article table {
    th, tr td {
      font-size: .875em;
    }
  }
  article .wrap .imagetexte.flex {
    margin-bottom: 30px;
  }
  footer .flex {
    > * {
      flex: auto;
      width: 100%;
      text-align: center;
    }
    #IC {
      margin-top: 30px;
    }
  }
}

@media all and (max-width: 480px) {
  #presentation, section#product, .prefooter {
    padding: 60px 0;
  }
  .wrap, #presentation .wrap {
    padding: 0 15px;
  }
  header {
    padding: 20px 15px;
    .wrap {
      padding: 0
    }
  }
  section#product .flex .item .caption, article .ourproduct .flex .item .caption {
    padding: 30px;
    .textcaption {
      p {
        line-height: 1.125em;
      }
      p.button {
        margin-top: 15px;
      }
    }
  }
  article .wrap {
    .imagetexte.flex, article .wrap h2:first-child {
      margin-bottom: 45px;
    }
    h3 {
      margin-bottom: 15px;
    }
  }
  article .wrap * + h2 {
    margin-top: 30px;
  }
  article.contact {
    .gform_wrapper {
      .gfieldset.orangebg, .gfieldset.whitebg {
        padding-left: 15px;
        padding-right: 15px;
      }
      .gfieldset li.choice {
        padding: 15px 30px;
      }
    }
  }
}
@media all {


	h1 {
		font-size:35px;
		line-height:1.125em;
	}

	h2 {
		text-align: center;
		font-family: $font-ssp;
		margin-bottom: 45px;
		color: $orange;
		display: inline-block;
		width: 100%;
		line-height:1.25em;
	}

	a {
		text-decoration: none;
		color: $orange;
	}

	ul {
		list-style: none;
	}

	img {
		border: 0;
		height: auto;
		max-width: 100%;
		vertical-align: bottom;
	}

	.wrap {
		margin: 0 auto;
		width:100%;
		max-width: 1630px;
	}

	.page-template-default {
		article .wrap {max-width:1170px;}
	}

	#menu-header a:hover {text-decoration: underline;}

	.wrap::after {
		display: block;
		clear: both;
		content:"";
	}

	a, a:hover {@include smoothanimation;}

	.flex {
		display: flex;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
	}

	.container {
		width: 80%;
		margin: auto;
	}

	body {
		font : normal 19px/29px $font-calibri ;
	}

	/*************************************** HEADER ****************************************************/
	header {
		left: 0;
		position: fixed;
		right: 0;
		z-index: 30;
		top: 0;
		width: 100%;
		margin: auto;
		padding: 20px 5%;
		background-color: $white;
		@include shadow(#777);
		.flex {
			align-items: center ;
			justify-content: space-between;
		}

		+ * {margin-top: 92px;}
	}

	/*** LANGUES ***/
	header .language {
		margin-left : auto ;
		.wpml-ls-legacy-dropdown {width : 100px ;}
		.wpml-ls-legacy-dropdown li.wpml-ls-slot-languages a.js-wpml-ls-item-toggle {
			border-color :transparent  ;
			&::after {
				display: block;
				width: 21px;
				height: 11px;
				content: '';
				background: url(./images/fleche.png) center center no-repeat;
				background-size: cover;
				position: absolute;
				border : none ;
				top: 12px;
				right: 15px;
				-webkit-transition: all .2s ease-in-out 0s;
				transition: all .2s ease-in-out 0s;
			}
			&:hover, &:focus {
				background : transparent ;
			}
			span.wpml-ls-native {
				text-transform: uppercase;
				color: $orange;
				letter-spacing: .05em;
				font-weight: 700;
			}
			a.js-wpml-ls-item-toggle span {
				display : none ;
			}
			a.js-wpml-ls-item-toggle span:first-letter {
				display : inline-block ;
			}


			/*span.wpml-ls-native {
                display : none ;
                &:first-letter  {
                    display : block ;
                }
            }*/
		}
		.wpml-ls-legacy-dropdown a{
			color : $orange ;
			border: none ;
			background : rgba(255,255,255,.8)  ;

		}
		.wpml-ls-legacy-dropdown .wpml-ls-sub-menu {border-top : none ; background : transparent  ; top : calc(100% + 20px) ; padding  : 10px 0 ;}
		.wpml-ls-legacy-dropdown a:hover, .wpml-ls-legacy-dropdown a:focus, .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a {
			background : rgba(255,255,255,.8) ;
			font-weight : 700 ;
			color : $orange ;

		}
		.wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a {background : transparent ;}
	}





	/************************** FIL ARIANE ****************************/

	/** contenu **/
	.bandeau {
		position : relative ;
		height: 475px;
		overflow: hidden;
		img {
			width : 100% ;
			height : 100%; object-fit: cover ;
		}
		.maintitle {
			position :absolute;
			bottom : 0 ;
			width :100% ;
			background :rgba(255,255,255,0.8) ;
			h1{
				font-size : 35px;
				padding: 25px 0 20px;
				color :$orange ;
				text-align :center ;
				text-transform : uppercase;
			}
		}
	}


	article {
		padding : 90px 0 ;
		-webkit-box-shadow: inset 0 0 42px -28px rgba(0,0,0,.27);
		box-shadow: inset 0 0 42px -28px rgba(0,0,0,.27);
		.wrap {
			// padding : 0 13% ;
			h2 {
				margin: 0 0 30px;
				display: inline-block;
				width: 100%;
				font-size:35px;
				&:first-child {margin-bottom:60px;}
			}
			* + h2 {margin-top:60px;}
			h3 {
				font-size: 25px;
				margin-bottom: 30px;
				font-weight: 500;
				color: $grey;
			}
			* + h3 {margin-top:30px;}
			p.margin-bottom {margin-bottom: 60px;}
			ul:not(.slides) {margin:15px 0;}
			.imagetexte.flex {
				align-items:top;
				margin : 0 0 60px;
				&:last-child {margin-bottom:0px;}
				.texte {
					width: 485px;
					text-align: justify;
					margin-left: 60px;
					flex: 0.75;
					line-height:1.4em;
				}
				.images {
					margin-top:5px;
					// width: calc(100% - 485px);
					width:565px; max-width:565px;
					flex: 1;
				}
				// + .imagetexte.flex{
				// 	margin-top: 60px;
				// }
			}
			.imagetexte.flex.pageproduit {
				align-items: flex-start;
			}

			.img-rightalign.imagetexte.flex .images{
				order : 2 ;
			}
			.img-rightalign.imagetexte.flex .texte{
				margin-right: 60px;
				margin-left : 0;
			}
		}
	}


	article li {
		padding-left: 1em;
		text-indent: -1em;
	}

	article li li {
		margin-left: 20px;
	}

	article li ul {
		margin-bottom: 5px;
	}

	article li:before {
		content: "\2022\ ";
		padding-right: 5px;
	}

	.alignright {
		float: right;
		margin-left: 30px;
	}

	.alignleft {
		float: left;
		margin-right: 30px;
	}

	.aligncenter {
		display: block;
		margin: 0 auto;
	}


	.flexslider {
		position :relative ;
		.flex-viewport {
			.slides li {
				padding-left :0;
				text-indent : 0;
				&:before {display:none;}
			}

		}
		.flex-control-nav {
			display: flex;
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			justify-content: center;
			margin-top:-30px; position:relative;
			li{
				padding-left:0;
				text-indent:0;
				list-style-type: none ;
				&:before {display:none;}
				a {
					font-size:0;
					background-color: rgba(255,99,25,0.5);
					width: 10px;
					height: 10px;
					border-radius: 50%;
					display: inline-block;
				}
				a.flex-active {background-color:rgba(255,99,25,1);}
				+ li {margin-left :10px;}
			}
		}
	}
	.flexslider {
		.flex-viewport {
			border:1px solid rgba(0,0,0,.05);
			max-height:360px;
		}
		// ul.slides {
		// 	li {border:1px solid rgba(0,0,0,.05);}
		// }
	}
	.flexslider.slidersmall {
		margin: 15px 0 30px;
		.flex-viewport {border:none;}
		ul.slides {
			li {border:1px solid rgba(0,0,0,.05);}
		}
	}

	/** catégorie **/
	/**.article-blog { padding: 35px 30px; background:#f2f2f2;}
		.article-blog + .article-blog { margin-top:35px;}
		.img-blog { float:left; margin-right:3%;}
		.texte-blog { overflow:auto;}
		.article-blog p.readmore a { margin-top:35px;}

		/* pagination */
	/**.pagination { margin-top:40px; text-align:center;}
	.pagination ul{display:table; margin:auto; border-collapse: separate; }
	.pagination li{display:table-cell; padding-right: 15px; width:55px; height:55px;}
	.pagination li span, .pagination li span.current, .pagination li a{ padding:12px 20px; border-radius:50%;  font-weight: 700; font-size: 20px; line-height:22px; text-align: center;}
	.pagination li a{ background:#f0eee6; color:#000;-webkit-transition: all 500ms ease-in-out; -o-transition: all 500ms ease-in-out; transition: all 500ms ease-in-out;}
	.pagination li a:hover, .pagination li span, .pagination li span.current{background:#404040; color:#fff;}
	article .pagination li:before { content:none;}




	/*************************************** PIED DE PAGE ****************************************************/

	.prefooter {
		background: $greyclear;
		padding: 75px 0;
		text-align: center;
		h2 {
			font-size: 35px;
			margin-bottom: 45px;
			color: $grey;
		}
		// #lightgallery.flex {
		#lightgallery {
			margin-top:30px;
			text-align:center;
			li {
				display:inline-block;
				margin:0 15px 30px;
				max-width:150px;
				img {max-width: 150px;}
				// flex : 1 ;
				// display:table ;
				// + li {margin-left : 35px;}
			}
		}
	}

	footer {
		padding: 30px 0;
		background-color: $orange;
		font-family: $font-calibri;
		font-weight: bold;

		.flex {
			align-items: center;
			justify-content: space-between;
			width: 100%;
			#menu-footer ul li {
				display: inline-block;
				a {
					color: $white;
					position: relative;
				}
				+ li a {
					padding-left: 20px;
					margin-left: 20px;
					&::before {
						content: '';
						height: 15px;
						width: 2px;
						background-color: $white;
						position: absolute;
						left: 0;
						top: 2px;
					}
				}
			}		
		}
		.IC-link, .IClink {
			img {height:20px;}
		}
	}
}